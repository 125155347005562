import { ReactNode } from "react";
import SingleContent from "../SingleContent";
import SingleRelatedPosts from "../SingleRelatedPosts";
import { PostDataType } from "data/types";

export interface LayoutProps {
  children: ReactNode;
  post?: PostDataType;
  authorPosts?: PostDataType[];
  relatedPosts?: PostDataType[];
}

const Layout = ({ children, post, authorPosts, relatedPosts }: LayoutProps) => {
  return (
    <div>
      {children}

      {/* SINGLE MAIN CONTENT */}
      <div className="container mt-10">
        <SingleContent post={post}/>
      </div>

      {/* RELATED POSTS */}
      <SingleRelatedPosts moreFromAuthorPosts={authorPosts} relatedPosts={relatedPosts}/>
    </div>
  );
};

export default Layout;