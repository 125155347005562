import SectionHero from "components/SectionHero/SectionHero";
import rightImg from "images/about-hero-right.png";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import ReactGA from "react-ga4";

const PageAbout = () => {
  if (process.env.NODE_ENV === "production") {
    ReactGA.initialize("G-2TNMKRKWDW");
    // Track pageview
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: window.location.pathname });
  }

  return (
    <div className={`nc-PageAbout relative`}>
      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <SectionHero
          rightImg={rightImg}
          heading="👋 About Us."
          btnText=""
          subHeading="
              We're a globetrotting clan passionate about exploring the world together. 
              Embark on unforgettable journeys with our family travel blog as we share tips, tales, and the joy of creating lasting memories across diverse destinations"
        />

        <SectionFounder />

        <div className="relative py-16">
          <BackgroundSection />
          <SectionStatistic />
        </div>

        <SectionSubscribe2 />
      </div>
    </div>
  );
};

export default PageAbout;