import React from "react";
import ButtonClose from "components/ButtonClose/ButtonClose";

export interface AlertProps {
  children: React.ReactNode;
  containerClassName?: string;
  type?: "default" | "warning" | "info" | "success" | "error";
}

export interface ContactFormAlertProps {
  result: string;
}

export const Alert: React.FC<AlertProps> = ({
  children = "Alert Text",
  containerClassName = "",
  type = "default",
}) => {
  let classes = containerClassName;
  switch (type) {
    case "default":
      classes += " text-black bg-neutral-900";
      break;
    case "info":
      classes += " bg-status-infoBg text-status-info";
      break;
    case "success":
      classes += " bg-status-successBg text-status-success";
      break;
    case "error":
      classes += " bg-status-errorBg text-status-error";
      break;
    case "warning":
      classes += " bg-status-warningBg text-status-warning";
      break;
    default:
      break;
  }

  return (
    <div
      className={`ttnc-alert relative flex items-center text-paragraph-base px-6 pt-4 pb-3 rounded-lg ${classes}`}
    >
      <i className="pe-7s-info text-2xl mr-2"></i>
      {children}
      <ButtonClose className="absolute top-4 right-6" />
    </div>
  );
};

export const ContactFormAlert: React.FC<ContactFormAlertProps> = ({
  result = "",
}) => {
  let className = result;
  let message = "";

  switch (result) {
    case "success":
      className += "px-4 py-3 leading-normal text-blue-700 bg-blue-100 rounded-full mb-5 text-center";
      message = "We have successfully received your message!"
      break;
    case "error":
      className += "px-4 py-3 leading-normal text-yellow-700 bg-yellow-100 rounded-full mb-5 text-center";
      message = "There was an error while trying to submit the form"
      break;
    default:
      break;
  }
  
  return (
    <div className={className}>
      <p>{message}</p>
    </div>
  );
}

export const NewsletterSubscribeAlert: React.FC<ContactFormAlertProps> = ({
  result = "",
}) => {
  let className = result;
  let message = "";

  switch (result) {
    case "success":
      className += "px-4 py-3 leading-normal text-blue-700 bg-blue-100 rounded-full mb-5 text-center";
      message = "We have added you to our list!"
      break;
    case "exists":
      className += "px-4 py-3 leading-normal text-yellow-700 bg-yellow-100 rounded-full mb-5 text-center";
      message = "Looks like you're already subscribed!"
      break;
    case "error":
      className += "px-4 py-3 leading-normal text-yellow-700 bg-yellow-100 rounded-full mb-5 text-center";
      message = "There was an error while trying to add you to our list."
      break;
    default:
      break;
  }
  
  return (
    <div className={className}>
      <p>{message}</p>
    </div>
  );
}