import avatar1 from "data/avatars/4.jpg";
import avatar2 from "data/avatars/4.jpg";
import avatar3 from "data/avatars/4.jpg";
import avatar4 from "data/avatars/4.jpg";

export const avatarImgs = [
  avatar1,
  avatar2,
  avatar3,
  avatar4,
];

const personNames = [
  "Anastasios Balaskas",
  "Maroula Pavlatou",
  "Angelos Balaskas",
  "Victor Balaskas",
];

const tagNames = [
  "Life",
  "Travel",
  "Music",
  "Beauty",
];

const featuredImgs = [
  "https://images.pexels.com/photos/1337753/pexels-photo-1337753.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  "https://images.pexels.com/photos/761963/pexels-photo-761963.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  "https://images.pexels.com/photos/374876/pexels-photo-374876.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  "https://images.pexels.com/photos/3225531/pexels-photo-3225531.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
];

export const imgHigtQualitys = [
  "https://images.pexels.com/photos/4352244/pexels-photo-4352244.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/3023211/pexels-photo-3023211.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/4066850/pexels-photo-4066850.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/6168061/pexels-photo-6168061.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
];

const aTitles = [
  "testing change est ultricies integer quis auctor elit sed vulputate",
  "in arcu cursus euismod quis viverra nibh cras pulvinar mattis",
  "natoque penatibus et magnis dis parturient montes nascetur ridiculus mus",
  "et leo duis ut diam quam nulla porttitor massa id",
  "turpis cursus in hac habitasse platea dictumst quisque sagittis purus",
  "ut faucibus pulvinar elementum integer enim neque volutpat ac tincidunt",
  "interdum velit euismod in pellentesque massa placerat duis ultricies lacus",
  "fringilla ut morbi tincidunt augue interdum velit euismod in pellentesque",
  "sagittis vitae et leo duis ut diam quam nulla porttitor",
  "in mollis nunc sed id semper risus in hendrerit gravida",
  "tellus integer feugiat scelerisque varius morbi enim nunc faucibus a",
  "eleifend mi in nulla posuere sollicitudin aliquam ultrices sagittis orci",
  "non sodales neque sodales ut etiam sit amet nisl purus",
];

function _getTitleRd() {
  return aTitles[Math.floor(Math.random() * aTitles.length)];
}
function _getPersonNameRd() {
  return personNames[Math.floor(Math.random() * personNames.length)];
}

function _getImgRd() {
  return featuredImgs[Math.floor(Math.random() * featuredImgs.length)];
}

function _getImgHightQualityRd() {
  return imgHigtQualitys[Math.floor(Math.random() * imgHigtQualitys.length)];
}

function _getTagNameRd() {
  return tagNames[Math.floor(Math.random() * tagNames.length)];
}
function _getAvatarRd(personName?: string) {
  //var index = personNames.findIndex(x => x === personName);
  return avatarImgs[1];
}

export {
  _getImgRd,
  _getTagNameRd,
  _getAvatarRd,
  _getImgHightQualityRd,
  _getTitleRd,
  _getPersonNameRd,
};
