import __authors from "./jsons/__users.json";
import { PostAuthorType } from "./types";
import { Route } from "routers/types";

const DEMO_AUTHORS: PostAuthorType[] = __authors.map((item, index) => ({
  ...item,
  avatar: item.avatar,
  href: item.href as Route,
}));

const AUTHORS_ASYNC = async (): Promise<PostAuthorType[]> => {
  try {
    const response = await fetch('/api/GetAuthors');
    if (response.ok) {
      const data = await response.json();

      const mappedAuthors = data.map((author: any, index: number) => {

        return {
          ...author
        } as PostAuthorType;
    });

      return mappedAuthors;
    } else {
      console.error('Error fetching posts:', response.statusText);
      return [];
    }
  } catch (error) {
    console.error('Error fetching posts:', error);
    return [];
  }
};

export { DEMO_AUTHORS, AUTHORS_ASYNC };
