import { FC, useEffect, useRef, useState } from "react";
import Tag from "components/Tag/Tag";
import SingleAuthor from "./SingleAuthor";
import SingleContentDemo from "./SingleContentDemo";
import useIntersectionObserver from "hooks/useIntersectionObserver";
import PostCardLikeAction from "components/PostCardLikeAction/PostCardLikeAction";
import PostCardCommentBtn from "components/PostCardCommentBtn/PostCardCommentBtn";
import { ArrowUpIcon } from "@heroicons/react/24/solid";
import { PostDataType } from "data/types";
import React from "react";
import NcImage from "components/NcImage/NcImage";
import { useNavigate, useSearchParams } from "react-router-dom";
import usePathname from "hooks/usePathname";
import { imgHigtQualitys } from "contains/fakeData";
import SingleHeader from "./SingleHeader";
import ListingImageGallery from "components/listing-image-gallery/ListingImageGallery";

export interface SingleContentProps {
  post?: PostDataType
}

// ... (imports)

const MemoizedPostCardLikeAction = React.memo(PostCardLikeAction);

const SingleContent: FC<SingleContentProps> = ({
  post = {} as PostDataType
}) => {
  // image gallery
  const router = useNavigate();
  const thisPathname = usePathname();
  const [searchParams] = useSearchParams();
  const modal = searchParams?.get("modal");

const handleOpenModalImageGallery = () => {
  const params = new URLSearchParams(searchParams);
  params.set("modal", "photo-tour");

  let newPath = `${thisPathname}${params.toString() ? `?${params.toString()}` : ''}`;
  newPath = newPath.replace(/\/+/g, '/'); // Remove consecutive slashes
  
  router(newPath);
};

const handleCloseModalImageGallery = () => {
  const params = new URLSearchParams(searchParams);
  params.delete("modal");

  let newPath = `${thisPathname}${params.toString() ? `?${params.toString()}` : ''}`;
  newPath = newPath.replace(/\/+/g, '/'); // Remove consecutive slashes
  
  router(newPath);
};

  
  
  
  //
  const endedAnchorRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const progressRef = useRef<HTMLButtonElement>(null);
  const [isShowScrollToTop, setIsShowScrollToTop] = useState<boolean>(false);
  const [isSingleContentRendered, setIsSingleContentRendered] = useState<boolean>(false);

  const endedAnchorEntry = useIntersectionObserver(endedAnchorRef, {
    threshold: 0,
    root: null,
    rootMargin: "0%",
    freezeOnceVisible: false,
  });

  useEffect(() => {
    const handleProgressIndicator = () => {
      const entryContent = contentRef.current;
      const progressBarContent = progressRef.current;

      if (!entryContent || !progressBarContent) {
        return;
      }

      const totalEntryH = entryContent.offsetTop + entryContent.offsetHeight;
      let winScroll =
        document.body.scrollTop || document.documentElement.scrollTop;
      let scrolled = (winScroll / totalEntryH) * 100;

      progressBarContent.innerText = scrolled.toFixed(0) + "%";

      if (scrolled >= 100) {
        setIsShowScrollToTop(true);
      } else {
        setIsShowScrollToTop(false);
      }
    };

    const handleProgressIndicatorHeadeEvent = () => {
      window?.requestAnimationFrame(handleProgressIndicator);
    };
    handleProgressIndicator();
    window?.addEventListener("scroll", handleProgressIndicatorHeadeEvent);

    // Set isSingleContentRendered to true after the rendering is complete
    setIsSingleContentRendered(true);

    return () => {
      window?.removeEventListener("scroll", handleProgressIndicatorHeadeEvent);
    };
  }, []);

  const showLikeAndCommentSticky =
    !endedAnchorEntry?.intersectionRatio &&
    (endedAnchorEntry?.boundingClientRect.top || 0) > 0;

  // Check if post is found before rendering
  if (!post) {
    return <p>Post not found</p>;
  }

  return (
    <div className="relative">
      <div className="nc-SingleContent space-y-10">
        {/* ENTRY CONTENT */}
        <div
          id="single-entry-content"
          className="prose lg:prose-lg !max-w-screen-md mx-auto dark:prose-invert"
          ref={contentRef}
        >
          <SingleContentDemo />
        </div>

        {/* TAGS */}
        <div className="max-w-screen-md mx-auto flex flex-wrap">
          {post?.categories.map((item) => (
            <Tag hideCount key={item.id} tag={item} className="mr-2 mb-2" />
          ))}
        </div>

        {post.galleryImgs && post.galleryImgs.length > 0 ? (
            <div className={`pt-8 lg:pt-16`}>
              <header className="container rounded-xl">
              <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-2 my-10">
              <div
                className="col-span-2 row-span-2 relative rounded-xl overflow-hidden cursor-pointer z-0"
                onClick={handleOpenModalImageGallery}
              >
                <NcImage
                  alt="single"
                  containerClassName="absolute inset-0"
                  className="object-cover w-full h-full rounded-xl"
                  fill
                  src={post.galleryImgs[0]}
                />
                <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
              </div>
              {post.galleryImgs.filter((_, i) => i >= 1 && i < 5).map(
                (item, index) => (
                  <div
                    key={index}
                    className={`relative rounded-xl overflow-hidden z-0 ${
                      index >= 2 ? "hidden sm:block" : ""
                    }`}
                  >
                    <NcImage
                      alt="single"
                      fill
                      containerClassName="aspect-w-6 aspect-h-5"
                      className="object-cover w-full h-full rounded-xl"
                      src={item || ""}
                    />

                    {/* OVERLAY */}
                    <div
                      className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                      onClick={handleOpenModalImageGallery}
                    />
                  </div>
                )
              )}

              <div
                className="absolute hidden md:flex md:items-center md:justify-center right-3 bottom-3 px-4 py-2 rounded-full bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
                onClick={handleOpenModalImageGallery}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                  />
                </svg>
                <span className="ml-2 text-neutral-800 text-sm font-medium">
                  Show all photos
                </span>
              </div>
            </div>
            </header>           

            <ListingImageGallery
              isShowModal={modal === "photo-tour"}
              onClose={handleCloseModalImageGallery}
              images={post.galleryImgs.map((item, index) => {
                return {
                  id: index,
                  url: item,
                };
              })}
            />
          </div>
        ) : null}

        {/* AUTHOR */}
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
        <div className="max-w-screen-md mx-auto ">
          <SingleAuthor author={post?.author}/>
        </div>

        {/* COMMENT FORM */}
        {/* <div
          id="comments"
          className="scroll-mt-20 max-w-screen-md mx-auto pt-5"
        >
          <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
            Responses (10)
          </h3>
          <SingleCommentForm />
        </div> */}

        {/* COMMENTS LIST */}
        {/* <div className="max-w-screen-md mx-auto">
          <SingleCommentLists />
          <div ref={endedAnchorRef}></div>
        </div> */}

        {/* Render PostCardLikeAction only if SingleContent has finished rendering */}
        {isSingleContentRendered && (
          <div
            className={`sticky mt-8 bottom-8 z-40 justify-center ${
              showLikeAndCommentSticky ? "flex" : "hidden"
            }`}
          >
            <div className="bg-white dark:bg-neutral-800 shadow-lg rounded-full ring-1 ring-offset-1 ring-neutral-900/5 p-1.5 flex items-center justify-center space-x-2 text-xs">
              <MemoizedPostCardLikeAction className="px-3 h-9 text-xs" likeCount={post.like.count} liked={post.like.isLiked}/>
              <div className="border-l h-4 border-neutral-200 dark:border-neutral-700"></div>
              <PostCardCommentBtn
                isATagOnSingle
                className={` flex px-3 h-9 text-xs`}
              />
              <div className="border-l h-4 border-neutral-200 dark:border-neutral-700"></div>

              <button
                className={`w-9 h-9 items-center justify-center bg-neutral-50 dark:bg-neutral-800 hover:bg-neutral-100 rounded-full ${
                  isShowScrollToTop ? "flex" : "hidden"
                }`}
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              >
                <ArrowUpIcon className="w-4 h-4" />
              </button>

              <button
                ref={progressRef}
                className={`w-9 h-9 items-center justify-center ${
                  isShowScrollToTop ? "hidden" : "flex"
                }`}
                title="Go to top"
              >
                %
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SingleContent;