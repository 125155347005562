import Heading from "components/Heading/Heading";

export interface Statistic {
  id: string;
  heading: string;
  subHeading: string;
}

const stats: Statistic[] = [
  {
    id: "1",
    heading: "100,000",
    subHeading:
      "Covered miles of travel experiences (as of Sept. 30, 2023)",
  },
  {
    id: "2",
    heading: "15+",
    subHeading:
      "Countries visited (as of Sept. 30, 2023)",
  },
];

const SectionStatistic = () => {
  return (
    <div className={`nc-SectionStatistic relative`}>
      <Heading
        desc="Our family blog inspires adventure, shares travel tips, and captures cherished moments"
      >
        🚀 Fast Facts
      </Heading>
      <div className="grid md:grid-cols-2 gap-5 lg:grid-cols-2 xl:gap-8">
        {stats.map((item) => (
          <div
            key={item.id}
            className="p-6 bg-white dark:bg-black/20 rounded-2xl dark:border-neutral-800"
          >
            <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-4xl dark:text-neutral-200">
              {item.heading}
            </h3>
            <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
              {item.subHeading}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionStatistic;