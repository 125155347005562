import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import NcPlayIcon from "components/NcPlayIcon/NcPlayIcon";
import CategoryBadgeList from "components/CategoryBadgeList/CategoryBadgeList";
import PostMeta2 from "components/PostMeta2/PostMeta2";
import isSafariBrowser from "utils/isSafariBrowser";
import Image from "components/Image";
import SingleTitle from "app/(singles)/SingleTitle";
import SingleMetaAction2 from "app/(singles)/SingleMetaAction2";
import Layout from "../layout";
import { PostDataType } from "data/types";
import ReactGA from "react-ga4";

const PageSingleVideo = () => {
  const [isPlay, setIsPlay] = useState(false);
  const [isRendered, setIsRendered] = useState(false);

  const [post, setPost] = useState<PostDataType | null>(null);
  const [authorPosts, setAuthorPosts] = useState<PostDataType[]>([]);
  const [relatedPosts, setRelatedPosts] = useState<PostDataType[]>([]);
  const [loading, setLoading] = useState(true);
  let isCounterUpdated = false;

  const pathname = window.location.pathname;

  const removePostById = (array: PostDataType[], idToRemove: string | number): PostDataType[] => {
    return array.filter(item => item.id !== idToRemove);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const postsResponse = await fetch(
          `/api/GetPostByPathname?pathname=${pathname}`
        );
        
        if (postsResponse.ok) {
          const postData = await postsResponse.json();
          setPost(postData);          
          setLoading(false);

          if (!isCounterUpdated) {
            incrementCounterForPost(postData);
          }
        }
      } catch (error) {
        console.error('Error fetching author-specific posts:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [pathname]);

  useEffect(() => {
    if (post && post.author.authorId) {
      const fetchPosts = async () => {
        try {
          const postsResponse = await fetch(
            `/api/GetPostsForAuthorId?authorId=${post.author.authorId}`
          );
          if (postsResponse.ok) {
            const postData = await postsResponse.json();
            setAuthorPosts(postData);
          }
        } catch (error) {
          console.error('Error fetching author-specific posts:', error);
        }
      };
  
      fetchPosts();
    }
  }, [post]);

  useEffect(() => {
    if (post && post.author.authorId) {
      const fetchPosts = async () => {
        try {
          const postsResponse = await fetch(
            `/api/GetPostsForGategoryId?categoryId=${post.categories[0].taxonomyId}`
          );
          if (postsResponse.ok) {
            const postData = await postsResponse.json();
            setRelatedPosts(postData);
          }
        } catch (error) {
          console.error('Error fetching related posts:', error);
        }
      };
  
      fetchPosts();
    }
  }, [post]);

  useEffect(() => {
    setIsRendered(true);
    
    if (process.env.NODE_ENV === "production") {
      ReactGA.initialize("G-2TNMKRKWDW");
      // Track pageview
      ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: window.location.pathname });
    }
  }, []);

  const incrementCounterForPost = async (post: PostDataType) => {
    try {
      // Mark the post to prevent multiple increments
      isCounterUpdated = true;

      // Make a request to your backend to increment the counter
      const response = await fetch(`/api/IncreasePostViewedCount?itemId=${post.id}&partitionKey=${post.index}`);

      if (!response.ok) {
        console.error('Error incrementing counter:', response.statusText);
      }
    } catch (error) {
      console.error('Error incrementing counter:', error);
    }
  };

  if (loading) {
    return <div className="text-center mt-4 mb-4">Loading article...</div>;
  }

  if (!post) {
    return <div className="text-center mt-4 mb-4">Article not found 🙁</div>;
  }

  const renderMainVideo = () => {
    return (
      <div className="">
        {isSafariBrowser() && !isPlay && (
          <div
            className="absolute inset-0 z-10 cursor-pointer "
            onClick={() => setIsPlay(true)}
          >
            <Image
              src={post.videoThumbnail ?? post.featuredImage}
              className="object-cover"
              sizes="(max-width: 1024px) 100vw, 50vw"
              alt="single"
            />
            <div className="absolute inset-0 flex items-center justify-center">
              <NcPlayIcon />
            </div>
          </div>
        )}
        {isRendered && (
          <ReactPlayer
            url={post.videoUrl}
            className="absolute inset-0"
            playing={isSafariBrowser() ? isPlay : true}
            width="100%"
            height="100%"
            controls
            light={
              isSafariBrowser()
                ? false
                : post.videoThumbnail ?? post.featuredImage
            }
            playIcon={<NcPlayIcon />}
          />
        )}
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div className={`nc-SingleHeader `}>
        <div className="space-y-5 dark text-neutral-100">
          <CategoryBadgeList
            itemClass="!px-3"
            categories={post.categories}
          />
          <SingleTitle
            mainClass="text-neutral-900 font-semibold text-3xl md:!leading-[120%] dark:text-neutral-100"
            title={post.title}
          />

          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
          <div className="flex flex-col space-y-5">
            <PostMeta2
              size="large"
              className="leading-none flex-shrink-0"
              hiddenCategories
              avatarRounded="rounded-full shadow-inner"
              post={post}
            />
            <SingleMetaAction2 post={post}/>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Layout post={post} authorPosts={authorPosts} relatedPosts={removePostById(relatedPosts, post.id)}>
      <header className="container relative py-14 lg:py-20 flex flex-col lg:flex-row lg:items-center">
        <div className="nc-PageSingleVideo__headerWrap absolute inset-y-0 transform translate-x-1/2 right-1/2 w-screen lg:translate-x-0 lg:w-[calc(100vw/2)] bg-neutral-900 dark:bg-black dark:bg-opacity-50 lg:rounded-r-[40px]"></div>
        <div className="pb-10 lg:pb-0 lg:pr-10 relative">{renderHeader()}</div>
        <div className="relative lg:w-8/12 flex-shrink-0">
        <div
          className="aspect-w-16 aspect-h-16 sm:aspect-h-9 border-4 border-neutral-300 dark:border-neutral-800 shadow-2xl bg-neutral-800 rounded-3xl overflow-hidden z-0"
          style={{ paddingBottom: "55%" }}
        >
          {renderMainVideo()}
        </div>
        </div>
      </header>
    </Layout>
  );
};

export default PageSingleVideo;