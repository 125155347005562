import NcImage from "components/NcImage/NcImage";
import SingleHeader from "app/(singles)/SingleHeader";
import Layout from "../layout";
import { useEffect, useState } from "react";
import { PostDataType } from "data/types";
import ReactGA from "react-ga4";

const PageSingle = () => {
  const [post, setPost] = useState<PostDataType | null>(null);
  const [authorPosts, setAuthorPosts] = useState<PostDataType[]>([]);
  const [relatedPosts, setRelatedPosts] = useState<PostDataType[]>([]);
  const [loading, setLoading] = useState(true);
  let isCounterUpdated = false;

  const pathname = window.location.pathname;

  const removePostById = (array: PostDataType[], idToRemove: string | number): PostDataType[] => {
    return array.filter(item => item.id !== idToRemove);
  };

  useEffect(() => {
    const fetchData = async () => {
    try {
        setLoading(true);

        const postsResponse = await fetch(
          `/api/GetPostByPathname?pathname=${pathname}`
        );
        
        if (postsResponse.ok) {
          const postData = await postsResponse.json();
          setPost(postData);          
          setLoading(false);

          if (!isCounterUpdated) {
            incrementCounterForPost(postData);
          }
        }
      } catch (error) {
        console.error('Error fetching author-specific posts:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [pathname]);

  useEffect(() => {
    if (post && post.author.authorId) {
      const fetchPosts = async () => {
        try {
          const postsResponse = await fetch(
            `/api/GetPostsForAuthorId?authorId=${post.author.authorId}`
          );
          if (postsResponse.ok) {
            const postData = await postsResponse.json();
            setAuthorPosts(postData);
          }
        } catch (error) {
          console.error('Error fetching author-specific posts:', error);
        }
      };
  
      fetchPosts();
    }
  }, [post]);

  useEffect(() => {
    if (post && post.author.authorId) {
      const fetchPosts = async () => {
        try {
          const postsResponse = await fetch(
            `/api/GetPostsForGategoryId?categoryId=${post.categories[0].taxonomyId}`
          );
          if (postsResponse.ok) {
            const postData = await postsResponse.json();
            setRelatedPosts(postData);
          }
        } catch (error) {
          console.error('Error fetching related posts:', error);
        }
      };
  
      fetchPosts();
    }
  }, [post]);

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      ReactGA.initialize("G-2TNMKRKWDW");
      // Track pageview
      ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: window.location.pathname });
    }
  }, []);

  const incrementCounterForPost = async (post: PostDataType) => {
    try {
      // Mark the post to prevent multiple increments
      isCounterUpdated = true;

      // Make a request to your backend to increment the counter
      const response = await fetch(`/api/IncreasePostViewedCount?itemId=${post.id}&partitionKey=${post.index}`);

      if (!response.ok) {
        console.error('Error incrementing counter:', response.statusText);
      }
    } catch (error) {
      console.error('Error incrementing counter:', error);
    }
  };

  if (loading) {
    return <div className="text-center mt-4 mb-4">Loading article...</div>;
  }

  if (!post) {
    return <div className="text-center mt-4 mb-4">Article not found 🙁</div>;
  }

  return (
    <Layout post={post} authorPosts={authorPosts} relatedPosts={removePostById(relatedPosts, post.id)}>
      <div className={`nc-PageSingle pt-8 lg:pt-16`}>
        <header className="container rounded-xl">
          <div className="max-w-screen-md mx-auto">
            <SingleHeader title={post.title} description={post.desc} post={post} categories={post?.categories}/>
          </div>
        </header>
        
        <NcImage
          alt="single"
          containerClassName="container my-10 sm:my-12"
          className="w-full rounded-xl"
          src={post.featuredImage}
          width={1260}
          height={750}
          sizes="(max-width: 1024px) 100vw, 1280px"
        />
      </div>
    </Layout>
  );
};

export default PageSingle;