import { useEffect, useState } from "react";
import Pagination from "components/Pagination/Pagination";
import ButtonPrimary from "components/Button/ButtonPrimary";
import { DEMO_CATEGORIES } from "data/taxonomies";
import Nav from "components/Nav/Nav";
import NavItem from "components/NavItem/NavItem";
import SocialsList from "components/SocialsList/SocialsList";
import ArchiveFilterListBox from "components/ArchiveFilterListBox/ArchiveFilterListBox";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import Card11 from "components/Card11/Card11";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
import ButtonSecondary from "components/Button/ButtonSecondary";
import SectionSliderNewAuthors from "components/SectionSliderNewAthors/SectionSliderNewAuthors";
import NcImage from "components/NcImage/NcImage";
import { GlobeAltIcon, ShareIcon } from "@heroicons/react/24/outline";
import VerifyIcon from "components/VerifyIcon";
import FollowButton from "components/FollowButton";
import NcDropDown from "components/NcDropDown/NcDropDown";
import { SOCIALS_DATA } from "components/SocialsShare/SocialsShare";
import AccountActionDropdown from "components/AccountActionDropdown/AccountActionDropdown";
import Image from "components/Image";
import twFocusClass from "utils/twFocusClass";
import { PostAuthorType, PostDataType } from "data/types";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

const FILTERS = [
  { name: "Most Recent" },
  { name: "Most Appreciated" },
  { name: "Most Discussed" },
  { name: "Most Viewed" },
];

const TABS = ["Articles"];

const PageAuthor = () => {
  const [author, setAuthor] = useState<PostAuthorType | undefined>();
  const [authors, setAuthors] = useState<PostAuthorType[]  | undefined>();
  const [authorPosts, setAuthorPosts] = useState<PostDataType[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [tabActive, setTabActive] = useState<string>(TABS[0]);
  const [showAllCategories, setShowAllCategories] = useState(false);

  const location = useLocation(); 
  const buttonText = showAllCategories ? 'Show me less' : 'Show me more';

  // Show only the top 4 categories by default when all categories are active
  const defaultCategoriesToShow = DEMO_CATEGORIES.filter(x => x.count! >= 1).slice(0, 4);

  // Combine default and active categories based on the state
  const trendingCategoriesToShow = showAllCategories
    ? DEMO_CATEGORIES
    : defaultCategoriesToShow;

  const handleShowMoreCategories = () => {
    setShowAllCategories(!showAllCategories);

  // Scroll to the top of the div
  const container = document.getElementById('aboveTrendingTopicsContainer');
    if (container) {
      container.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(4);
  const [itemsToShow, setItemsToShow] = useState(itemsPerPage);

  // Calculate the index range for the current page
  const startIndex = (currentPage - 1) * itemsToShow;
  const endIndex = startIndex + itemsToShow;

  // Get the posts for the current page
  const currentPosts = authorPosts.slice(startIndex, endIndex);

  // Calculate total number of pages
  const totalPages = Math.ceil(authorPosts.length / itemsToShow);

  // Disable "Show me less" button when there are fewer than 4 posts on desktop
  const isShowLessDisabled = itemsToShow <= (window.innerWidth >= 640 ? 4 : 2);

  const isShowMoreHidden = currentPage === totalPages;
  const isShowLessHidden = currentPage === 1;

  // Function to handle page change
  const handlePageChange = (newPage: number, event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setCurrentPage(newPage);
  };

  const handleShowMorePosts = () => {
    // Increment the number of items to show on each "Show me more" click
    setItemsToShow(itemsToShow + (window.innerWidth >= 640 ? 4 : 2));
    filterPosts(selectedFilter); // Apply filtering
  };

  const handleShowLessPosts = () => {
    // Ensure itemsToShow is greater than 1 before reducing
    const newItemsToShow = itemsToShow - (window.innerWidth >= 640 ? 4 : 2);
    const minimumItemsToShow = window.innerWidth >= 640 ? 4 : 2;
  
    setItemsToShow(Math.max(newItemsToShow, minimumItemsToShow));
    filterPosts(selectedFilter); // Apply filtering
  };

  // New state to keep track of the selected filter
  const [selectedFilter, setSelectedFilter] = useState({ name: "Most Recent" });
  const [filteredAuthorPosts, setFilteredAuthorPosts] = useState([...authorPosts]);
  
  // Function to filter posts based on the selected filter
  const filterPosts = (filter: { name: string }) => {
    switch (filter.name) {
      case "Most Recent":
        setFilteredAuthorPosts([...authorPosts].sort((a, b) => compareDatesDesc(a.date, b.date)));
        break;
      case "Most Appreciated":
        setFilteredAuthorPosts([...authorPosts].sort((a, b) => b.like.count - a.like.count));
        break;
      case "Most Discussed":
        setFilteredAuthorPosts([...authorPosts].sort((a, b) => b.commentCount - a.commentCount));
        break;
      case "Most Discussed":
        setFilteredAuthorPosts([...authorPosts].sort((a, b) => b.viewdCount - a.viewdCount));
        break;
      default:
        // Default to unfiltered
        setFilteredAuthorPosts([...authorPosts]);
        break;
    }
  };

  // Function to compare dates in descending order
  const compareDatesDesc = (dateA: string, dateB: string) => {
    const parsedDateA = new Date(dateA);
    const parsedDateB = new Date(dateB);

    if (parsedDateA > parsedDateB) return -1;
    if (parsedDateA < parsedDateB) return 1;
    return 0;
  };

  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    // Fetch author data based on the current URL pathname
    const fetchAuthorData = async () => {
      setIsLoading(true);
      const pathname = location.pathname;
      const firstName = pathname.substring(pathname.lastIndexOf("/") + 1);

      try {
        const authorResponse = await fetch(
          `/api/GetAuthorByFirstName?firstName=${firstName}`
        );
        if (authorResponse.ok) {
          const authorData = await authorResponse.json();
          setAuthor(authorData);
        } else {
          setError("Error fetching author data");
        }
      } catch (error) {
        setError("Error fetching author data");
      } finally {
        setIsLoading(false);
      }
    };

    fetchAuthorData();

    if (process.env.NODE_ENV === "production") {
      ReactGA.initialize("G-2TNMKRKWDW");
      // Track pageview
      ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: window.location.pathname });
    }
  }, [location.pathname]);

  useEffect(() => {
    if (author?.authorId) {
      const fetchPosts = async () => {
        setIsLoading(true);
        try {
          const postsResponse = await fetch(
            `/api/GetPostsForAuthorId?authorId=${author.authorId}`
          );
          if (postsResponse.ok) {
            const postData = await postsResponse.json();
            setAuthorPosts(postData);
          } else {
            setError("Error fetching author posts");
          }
        } catch (error) {
          setError("Error fetching author posts");
        } finally {
          setIsLoading(false);
        }
      };

      fetchPosts();
    }
  }, [author]);

  useEffect(() => {
    // Fetch authors
    const fetchAuthorData = async () => {
      setIsLoading(true);

      try {
        const authorResponse = await fetch(
          `/api/GetAuthors`
        );
        if (authorResponse.ok) {
          const authorData = await authorResponse.json();
          setAuthors(authorData);
        } else {
          setError("Error fetching authors data");
        }
      } catch (error) {
        setError("Error fetching authors data");
      } finally {
        setIsLoading(false);
      }
    };

    fetchAuthorData();
  }, []);

  // Effect to apply filtering whenever the selected filter changes
  useEffect(() => {
    filterPosts(selectedFilter);
  }, [selectedFilter, authorPosts]);

    // Update itemsToShow on window resize
  useEffect(() => {
      const handleResize = () => {
        setItemsToShow(window.innerWidth >= 640 ? 4 : 2);
      };
  
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
  }, []);
  
  const handleClickTab = (item: string) => {
    if (item === tabActive) {
      return;
    }
    setTabActive(item);
  };

  if (isLoading) {
    return <div className="text-center mt-4 mb-4">Loading author information...</div>;
  }

  if (!author) {
    return <div className="text-center mt-4 mb-4">Author information not found 🙁</div>;
  }

  if (error) {
    return <div className="text-center mt-4 mb-4">Sorry, something went wrong 🙁: {error}</div>;
  }

  return (
    <div className={`nc-PageAuthor `}>
      {/* HEADER */}
      <div className="w-full">
        <div className="relative w-full h-40 md:h-60 2xl:h-72">
          <NcImage
            alt=""
            containerClassName="absolute inset-0"
            sizes="(max-width: 1280px) 100vw, 1536px"
            src={author?.bgImage}
            className="object-cover w-full h-full"
            fill
          />
        </div>
        <div className="container -mt-10 lg:-mt-16">
          <div className="relative bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 p-5 lg:p-8 rounded-3xl md:rounded-[40px] shadow-xl flex flex-col md:flex-row">
            <div className="w-32 lg:w-40 flex-shrink-0 mt-12 sm:mt-0">
              <div className="wil-avatar relative flex-shrink-0 inline-flex items-center justify-center overflow-hidden text-neutral-100 uppercase font-semibold rounded-full w-20 h-20 text-xl lg:text-2xl lg:w-36 lg:h-36 ring-4 ring-white dark:ring-0 shadow-2xl z-0">
                <Image
                  alt="Avatar"
                  src={author?.avatar}
                  fill
                  className="object-cover"
                />
              </div>
            </div>

            {/*  */}
            <div className="pt-5 md:pt-1 lg:ml-6 xl:ml-12 flex-grow">
              <div className="max-w-screen-sm space-y-3.5 ">
                <h2 className="inline-flex items-center text-2xl sm:text-3xl lg:text-4xl font-semibold">
                  <span>{author?.firstName} {author?.lastName}</span>
                  <VerifyIcon
                    className="ml-2"
                    iconClass="w-6 h-6 sm:w-7 sm:h-7 xl:w-8 xl:h-8"
                  />
                </h2>
                <span className="block text-sm text-neutral-500 dark:text-neutral-400">
                  {author?.desc}
                </span>
                <a
                  href="##"
                  className="flex items-center text-xs font-medium space-x-2.5 cursor-pointer text-neutral-500 dark:text-neutral-400 truncate"
                >
                  <GlobeAltIcon className="flex-shrink-0 w-4 h-4" />
                  <span className="text-neutral-700 dark:text-neutral-300 truncate">
                  <a href={`mailto:${author?.email}`}>{author?.email}</a>
                  </span>
                </a>
                <SocialsList itemClass="block w-7 h-7" />
              </div>
            </div>

            {/*  */}
            <div className="absolute md:static left-5 right-5 top-4 sm:left-auto sm:top-5 sm:right-5 flex justify-end">
              <FollowButton
                isFollowing={false}
                fontSize="text-sm md:text-base font-medium"
                sizeClass="px-4 py-1 md:py-2.5 h-8 md:!h-10 sm:px-6 lg:px-8"
              />

              <div className="mx-2">
                <NcDropDown
                  className="flex-shrink-0 flex items-center justify-center focus:outline-none h-10 w-10 bg-neutral-100 hover:bg-neutral-200 dark:bg-neutral-800 dark:hover:bg-neutral-700 text-neutral-700 dark:text-neutral-200 rounded-full"
                  renderTrigger={() => <ShareIcon className="h-5 w-5" />}
                  onClick={() => {}}
                  data={SOCIALS_DATA}
                />
              </div>

              <AccountActionDropdown containerClassName="h-10 w-10 bg-neutral-100 hover:bg-neutral-200 dark:bg-neutral-800 dark:hover:bg-neutral-700" />
            </div>
          </div>
        </div>
      </div>
      {/* ====================== END HEADER ====================== */}

      <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
        <main>
          {/* TABS FILTER */}
          <div className="flex flex-col sm:items-center sm:justify-between sm:flex-row">
            <Nav className="sm:space-x-2">
              {TABS.map((item, index) => (
                <NavItem
                  key={index}
                  isActive={tabActive === item}
                  onClick={() => handleClickTab(item)}
                >
                  {item}
                </NavItem>
              ))}
            </Nav>
            <div className="block my-4 border-b w-full border-neutral-300 dark:border-neutral-500 sm:hidden"></div>
            <div className="flex justify-end">
              <ArchiveFilterListBox lists={FILTERS} onChange={setSelectedFilter} />
            </div>
          </div>

          {/* LOOP ITEMS */}
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8 mt-8 lg:mt-10">
            {filteredAuthorPosts.slice(0, itemsToShow).map((post) => (
              <Card11 key={post.id} post={post} />
            ))}
          </div>

          {/* PAGINATIONS */}
          <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
            {/* Pagination controls on the left */}
            <div className="nc-Pagination inline-flex space-x-1 text-base font-medium">
              {/* Render page numbers */}
              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index + 1}
                  onClick={(e) => handlePageChange(index + 1, e)}
                  className={`inline-flex w-11 h-11 items-center justify-center rounded-full ${
                    currentPage === index + 1
                      ? 'bg-primary-6000 text-white'
                      : 'bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700'
                  } ${twFocusClass()}`}
                >
                  {index + 1}
                </button>
              ))}
            </div>

            {/* POSTS - "Show me more" */}
            <div id="aboveTrendingTopicsContainer" className="sm:flex items-center space-x-3">
              {/* "Show me more" button */}
              {!isShowMoreHidden && authorPosts.length > itemsToShow && (
                <ButtonPrimary className="w-full sm:w-auto" onClick={handleShowMorePosts}>
                  Show me more
                </ButtonPrimary>
              )}

              {/* POSTS - "Show me less" */}
              {!isShowLessHidden && itemsToShow > 1 && (
              <ButtonPrimary className="w-full sm:w-auto" onClick={handleShowLessPosts} disabled={isShowLessDisabled}
                >
                  Show me less
                </ButtonPrimary>
              )}
            </div>
          </div>

          {/* Display the current items */}
          {currentPosts.map((post, index) => (
            // Render each item here
            <div key={index}>{/* Render your post item here */}</div>
          ))}
          
        </main>

        {/* TRENDING TOPICS */}
        <div className="relative py-16">
            <BackgroundSection />
            <SectionGridCategoryBox
              categories={trendingCategoriesToShow}
            />
            <div className="flex justify-end mt-10 md:mt-16">
              <ButtonSecondary className="w-full sm:w-auto" onClick={handleShowMoreCategories}>{buttonText}</ButtonSecondary>
            </div>
          </div>

        {/* AUTHORS */}
        <SectionSliderNewAuthors
                    heading="The dream team"
                    subHeading="Discover our elite writers"
                    authors={authors}
                  />

          {/* SUBCRIBE */}
          <SectionSubscribe2 />
      </div>
    </div>
  );
};

export default PageAuthor;