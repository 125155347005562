import React, { FC, useEffect, useState } from "react";
import ButtonCircle from "components/Button/ButtonCircle";
import rightImg from "images/newsletter.png";
import Input from "components/Input/Input";
import Image from "components/Image";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { NewsletterSubscribeAlert } from "components/Alert/Alert";

export interface SectionSubscribe2Props {
  className?: string;
}

const SectionSubscribe2: FC<SectionSubscribe2Props> = ({ className = "" }) => {
  const [email, setEmail] = useState<string>("");
  const [subscriptionMessage, setSubscriptionMessage] = useState<string | null>(null);
  const [status, setStatus] = useState('');

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      // Call GetSubscriptionByEmail
      const getResponse = await fetch(`/api/GetSubscriptionByEmail?email=${email}`);

      if (getResponse.ok) {
        // Subscription exists, display message
        // could potentially use this if we want to show since when: const getSubscriptionData = await getResponse.json();
        setSubscriptionMessage("Looks like you're already subscribed!");
        setEmail("");
        setStatus('exists');
      } else if (getResponse.status === 404) {
        // Subscription doesn't exist, call CreateNewSubscription
        const createResponse = await fetch(`/api/CreateNewSubscription?email=${email}`, { method: 'POST' });

        if (createResponse.ok) {
          // Subscription created successfully
          setSubscriptionMessage("We have added you to our list!");
          setEmail("");
          setStatus('success');
        } else {
          // Handle error when creating subscription
          setSubscriptionMessage("Error while subscribing. Please try again later.");
          setEmail("");
          setStatus('error');
        }
      } else {
        // Handle other errors when checking subscription
        setSubscriptionMessage("Error checking subscription. Please try again later.");
        setEmail("");
        setStatus('error');
      }
    } catch (error) {
      // Handle unexpected errors
      setSubscriptionMessage("Unexpected error. Please try again later.");
      setEmail("");
      setStatus('error');
    }
  };

  useEffect(() => {
    if(status === 'success' || status === 'exists') {
      setTimeout(() => {
        setStatus('');
      }, 3000);
    } else {
      setTimeout(() => {
        setStatus('');
      }, 8000);
    }
  }, [status]);
  
  return (
    <div
      className={`nc-SectionSubscribe2 relative flex flex-col lg:flex-row items-center ${className}`}
    >
      <div className="flex-shrink-0 mb-14 lg:mb-0 lg:mr-10 lg:w-2/5">
        <h2 className="font-semibold text-4xl">Join our newsletter 🎉</h2>
        <span className="block mt-6 mb-6 text-neutral-500 dark:text-neutral-400 margin-bottom:1.5rem">
          Subscribe to our newsletter for exclusive travel tips, destination insights, and a firsthand look into our family adventures.
          Join the global wanderlust community!
        </span>

        <NewsletterSubscribeAlert result={status}></NewsletterSubscribeAlert>

        <form className="mt-10 relative max-w-sm" onSubmit={handleSubmit}>
          <Input
              required
              aria-required
              placeholder="Enter your email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
          />
          <ButtonCircle
            type="submit"
            className="absolute transform top-1/2 -translate-y-1/2 right-1 dark:bg-neutral-300 dark:text-black"
          >
            <ArrowRightIcon className="w-5 h-5 " />
          </ButtonCircle>
        </form>
      </div>
      <div className="flex-grow">
        <Image
          alt="subsc"
          sizes="(max-width: 768px) 100vw, 50vw"
          src={rightImg}
        />
      </div>
    </div>
  );
};

export default SectionSubscribe2;