import React, { useEffect, useState } from "react";
import { DEMO_POSTS, DEMO_POSTS_AUDIO } from "data/posts";
import SectionHero from "components/SectionHero/SectionHero";
import rightImg from "images/justgo-homepage-2.png";
import Vector1 from "images/Vector1.png";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionSliderNewAuthors from "components/SectionSliderNewAthors/SectionSliderNewAuthors";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
import Image from "components/Image";
import SectionMagazine5 from "components/Sections/SectionMagazine5";
import SectionSliderPosts from "components/Sections/SectionSliderPosts";
import SectionAds from "components/Sections/SectionAds";
import SectionMagazine8 from "components/Sections/SectionMagazine8";
import SectionMagazine9 from "components/Sections/SectionMagazine9";
import SectionVideos from "components/Sections/SectionVideos";
import SectionLatestPosts from "components/Sections/SectionLatestPosts";
import SectionLargeSlider from "./SectionLargeSlider";
import { PostDataType, TaxonomyType } from "data/types";
import ReactGA from "react-ga4";

const homePagePosts: PostDataType[] = [
  {
    id: "1",
    index: 1,
    author: {
      id: "JustGo",
      firstName: "Just",
      lastName: "Go",
      displayName: "JustGo",
      avatar: "https://justgo.blob.core.windows.net/img/justgo-homepage-post.png",
      count: 0,
      desc: "Travel Blog",
      jobName: "Travel Blog", // replace with your actual job name
      href: "/"
    },
    date: "2024-01-27",
    href: "/",
    categories: [
      {
        id: 1,
        name: "Homepage",
        href: "/",
        taxonomy: "category",
      }
    ],
    title: "Just Go",
    featuredImage: "https://justgo.blob.core.windows.net/img/justgo-homepage-post.png",
    like: {
      count: 0,
      isLiked: false,
    },
    bookmark: {
      count: 0,
      isBookmarked: false,
    },
    commentCount: 0,
    viewdCount: 0,
    readingTime: 0,
    postType: "standard",
  }
];

const PageHome = () => {
  const [posts, setPosts] = useState<PostDataType[]>([]);
  const [categories, setCategories] = useState<TaxonomyType[]>([]);

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      ReactGA.initialize("G-2TNMKRKWDW");
      // Track pageview
      ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: window.location.pathname });
    }
  }, []);

  useEffect(() => {
    const fetchData = async (endpoint: string, expirationTime: number = 86400) => { // 86400 seconds (24 h)
      // Check if the data and timestamp are already cached in localStorage
      const cachedData = localStorage.getItem(endpoint);
      const cachedTimestamp = localStorage.getItem(`${endpoint}_timestamp`);
      
      if (cachedData && cachedTimestamp) {
        // Parse cached timestamp and calculate time difference
        const currentTime = new Date().getTime();
        const lastFetchedTime = new Date(parseInt(cachedTimestamp)).getTime();
        const elapsedTime = currentTime - lastFetchedTime;
        
        // Check if elapsed time is within the expiration time
        if (elapsedTime < expirationTime * 1000) {
          // If cached data is still valid, return it
          return JSON.parse(cachedData);
        }
      }
    
      // If data is not cached or expired, fetch it from the API
      const response = await fetch(endpoint);
      const data = await response.json();
    
      // Store the fetched data and current timestamp in localStorage for future use
      localStorage.setItem(endpoint, JSON.stringify(data));
      localStorage.setItem(`${endpoint}_timestamp`, new Date().getTime().toString());
      
      return data;
    };    

    const fetchDataAndSetState = async () => {
      const [postsResponse, categoriesResponse] = await Promise.all([
        fetchData('/api/GetPosts'),
        fetchData('/api/GetTaxonomies')
      ]);
  
      setPosts(postsResponse);
      setCategories(categoriesResponse);
    };
  
    fetchDataAndSetState();
  }, []);
  
  return (
    <div className="nc-PageHomeDemo3 relative">
      <div className="container relative">
        <SectionLargeSlider
          className="pt-10 pb-16 md:py-16 lg:pb-28 lg:pt-20"
          posts={homePagePosts}
        />

        {/* Latest posts */}
        {posts ? (
          <SectionLatestPosts
            posts={posts}
            categories={categories}
            postCardName="card7"
            gridClass="sm:grid-cols-2"
            className="pb-16 lg:pb-28"
          />
        ) : (
          <div>Loading latest articles...</div>
        )}

        {/* Editor picks */}
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionMagazine5 heading="🧩 Editor Picks" posts={MAGAZINE1_POSTS} />
        </div> */}

        {/* Articles a bout a specific subject */}
        {/* <SectionSliderPosts
          className="py-16 lg:py-28"
          postCardName="card10"
          heading="Sea travel enthusiast"
          subHeading="Over 218 articles about sea travel"
          posts={POSTS.filter((_, i) => i < 8)}
        /> */}

        {/* <SectionVideos className="py-16 lg:py-28" /> */}

        {/* <SectionSubscribe2 className="pb-16 lg:pb-28" /> */}
      </div>
    </div>
  );
};

export default PageHome;
