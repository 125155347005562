import Image from "components/Image";
import { Pairs, PostDataType } from "data/types";
import { useEffect, useState } from "react";

const SingleContentDemo = () => {
  const [post, setPost] = useState<PostDataType | null>(null);
  const pathname = window.location.pathname;

  useEffect(() => {
    const fetchData = async () => {
      const postsResponse = await fetch(
        `/api/GetPostByPathname?pathname=${pathname}`
      );
      if (postsResponse.ok) {
        const postData = await postsResponse.json();
        setPost(postData);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {post?.introParagraphs && post?.introParagraphs.length > 0 && (
        post?.introParagraphs.map((introParagraph, index) => (
          <p key={index}>{introParagraph}</p>
        ))
      )}

      <div>
        {post?.contentPairs && post?.contentPairs.length > 0 && (
          post?.contentPairs.map((contentPair: string | Pairs, index: number) => {
            if (typeof contentPair === 'string') {
              return <p key={index}>{contentPair}</p>;
            } else {
              return (
                <div key={index}>
                  <h3>{contentPair.header}</h3>
                  {/* Check if paragraph is an array */}
                  {Array.isArray(contentPair.paragraph) ? (
                    // If it's an array, map over it and render each string in its own <p> tag
                    contentPair.paragraph.map((paragraph, idx) => (
                      <p key={idx}>{paragraph}</p>
                    ))
                  ) : (
                    // If it's a single string, render it in a <p> tag
                    <p>{contentPair.paragraph}</p>
                  )}
    
                  {contentPair.image && (
                    <figure>
                      <Image
                        src={contentPair.image.link}
                        alt="nc blog"
                        sizes="(max-width: 1024px) 100vw, 1240px"
                        className="rounded-2xl"
                        width={1635}
                        height={774}
                      />
                      <figcaption>{contentPair.image.caption}</figcaption>
                    </figure>
                  )}
                </div>
              );
            }
          })
        )}
      </div>
      
      {post?.conclusion && post?.conclusion.length > 0 && (
        post?.conclusion.map((conclusion, index) => (
          <p key={index}>{conclusion}</p>
        ))
      )}
    </>
  );
};

export default SingleContentDemo;