import { FC, useEffect, useState } from "react";
import Card3 from "components/Card3/Card3";
import Heading from "components/Heading/Heading";
import WidgetTags from "components/WidgetTags/WidgetTags";
import { PostDataType, TaxonomyType } from "data/types";
import WidgetCategories from "components/WidgetCategories/WidgetCategories";
import WidgetAuthors from "components/WidgetAuthors/WidgetAuthors";
import WidgetPosts from "components/WidgetPosts/WidgetPosts";
import Pagination from "components/Pagination/Pagination";
import ButtonPrimary from "components/Button/ButtonPrimary";
import Card4 from "components/Card4/Card4";
import Card7 from "components/Card7/Card7";
import Card9 from "components/Card9/Card9";
import Card10 from "components/Card10/Card10";
import Card11 from "components/Card11/Card11";
import Card14 from "components/Card14/Card14";
import twFocusClass from "utils/twFocusClass";

export interface SectionLatestPostsProps {
  posts?: PostDataType[];
  categories?: TaxonomyType[];
  gridClass?: string;
  className?: string;
  heading?: string;
  postCardName?:
    | "card3"
    | "card4"
    | "card7"
    | "card9"
    | "card10"
    | "card11"
    | "card14";
}

const SectionLatestPosts: FC<SectionLatestPostsProps> = ({
  posts = undefined,
  categories = undefined,
  postCardName = "card3",
  heading = "Latest Articles 🎈",
  gridClass = "",
  className = "",
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(4);
  const [itemsToShow, setItemsToShow] = useState(itemsPerPage);

  // Calculate total number of pages
  const totalPages = Math.ceil(posts!.length / itemsToShow);

  // Calculate the index range for the current page
  const startIndex = (currentPage - 1) * itemsToShow;
  const endIndex = Math.min(startIndex + itemsToShow, posts!.length);

  // Get the posts for the current page
  const currentPosts = posts!.slice(startIndex, endIndex);

  // Function to handle page change
  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  // Show more items on click
  const handleShowMorePosts = () => {
    setItemsToShow(posts!.length); // Show all items
  };

  // Show fewer items on click
  const handleShowLessPosts = () => {
    setItemsToShow(itemsPerPage); // Show default number of items per page
  };
  
  // Determine if "Show more" and "Show less" buttons should be hidden
  const isShowMoreHidden = currentPage === totalPages;
  const isShowLessHidden = currentPage === 1;

  const renderCard = (post: PostDataType, index: number) => {
    switch (postCardName) {
      case "card3":
        return <Card3 key={index} className="py-3" post={post} />;
      case "card4":
        return <Card4 key={index} post={post} />;
      case "card7":
        return <Card7 key={index} post={post} ratio="aspect-w-5 aspect-h-5" />;
      case "card9":
        return <Card9 key={index} post={post} />;
      case "card10":
        return <Card10 key={index} post={post} />;
      case "card11":
        return <Card11 key={index} post={post} />;
      case "card14":
        return <Card14 key={index} post={post} />;
      default:
        return null;
    }
  };

  return (
    <div className={`nc-SectionLatestPosts relative ${className}`}>
      <div className="flex flex-col lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 xl:pr-14">
          <Heading>{heading}</Heading>

          {/* LOOP ITEMS */}
          <div className={`grid gap-6 md:gap-8 ${gridClass}`}>
            {currentPosts.map((post) => (
              <Card11 key={post.id} post={post} />
            ))}
          </div>

          {/* PAGINATIONS */}
          <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
            {/* Pagination controls on the left */}
            <div className="nc-Pagination inline-flex space-x-1 text-base font-medium">
              {/* Render page numbers */}
              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index + 1}
                  onClick={() => handlePageChange(index + 1)}
                  className={`inline-flex w-11 h-11 items-center justify-center rounded-full ${
                    currentPage === index + 1
                      ? 'bg-primary-6000 text-white'
                      : 'bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700'
                  } ${twFocusClass()}`}
                >
                  {index + 1}
                </button>
              ))}
            </div>

            {/* "Show me more" and "Show me less" buttons */}
            <div id="aboveTrendingTopicsContainer" className="sm:flex items-center space-x-3">
              {!isShowMoreHidden && currentPage < totalPages && (
                <ButtonPrimary className="w-full sm:w-auto" onClick={handleShowMorePosts}>
                  Show me more
                </ButtonPrimary>
              )}
              {itemsToShow !== itemsPerPage && ( // Show "Show me less" if more than default items are shown
                <ButtonPrimary className="w-full sm:w-auto" onClick={handleShowLessPosts}>
                  Show me less
                </ButtonPrimary>
              )}
            </div>

          </div>
        </div>

        <div className="w-full space-y-7 mt-24 lg:mt-0 lg:w-2/5 lg:pl-10 xl:pl-0 xl:w-1/3 ">
          {categories ? (
              <>
                {/* More tags */}
                <WidgetTags tags={categories}/>

                {/* Trending topic */}
                <WidgetCategories categories={categories} />

                {/* Popular posts */}
                <WidgetPosts posts={posts?.sort((a, b) => b.viewdCount - a.viewdCount).slice(0,4)}/>
              </>
            ) : ( <div>Loading content...</div>)
          }
        </div>
      </div>
    </div>
  );
};

export default SectionLatestPosts;