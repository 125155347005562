import React, { useEffect, useState } from "react";
import ModalCategories from "../ModalCategories";
import { PostAuthorType, PostDataType, TaxonomyType } from "data/types";
import ButtonPrimary from "components/Button/ButtonPrimary";
import ArchiveFilterListBox from "components/ArchiveFilterListBox/ArchiveFilterListBox";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import Card11 from "components/Card11/Card11";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
import ButtonSecondary from "components/Button/ButtonSecondary";
import SectionSliderNewAuthors from "components/SectionSliderNewAthors/SectionSliderNewAuthors";
import Image from "components/Image";
import twFocusClass from "utils/twFocusClass";
import ReactGA from "react-ga4";

const FILTERS = [
  { name: "Most Recent" },
  { name: "Most Appreciated" },
  { name: "Most Discussed" },
  { name: "Most Viewed" },
];

const PageArchive = () => {
  const [posts, setPosts] = useState<PostDataType[]>([]);
  const [authors, setAuthors] = useState<PostAuthorType[] | undefined>();
  const [showAllCategories, setShowAllCategories] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(4);
  const [itemsToShow, setItemsToShow] = useState(itemsPerPage);
  const [selectedFilter, setSelectedFilter] = useState({ name: "Most Recent" });
  const [filteredAuthorPosts, setFilteredAuthorPosts] = useState<PostDataType[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [categories, setCategories] = useState<TaxonomyType[]>();
  const [defaultCategoriesToShow, setDefaultCategoriesToShow] = useState<TaxonomyType[]>([]);

  const buttonText = showAllCategories ? 'Show me less' : 'Show me more';

  const handleShowMoreCategories = () => {
    setShowAllCategories(!showAllCategories);

    setShowModal(false);

    // Scroll to the top of the div
    const container = document.getElementById('aboveTrendingTopicsContainer');
    if (container) {
      container.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  };

  useEffect(() => {
    const fetchDataAsync = async (endpoint: string) => {
      const response = await fetch(endpoint);
      const data = await response.json();
      return data;
    };

    const fetchDataAndSetState = async () => {
      const fullPath = window.location.pathname;
      let postsGetRoute = "";
      let categoriesRoute = "";
      let authorsRoute = "";

      if (fullPath === '/archive/summary') {
        postsGetRoute = '/api/GetPosts';
      } else if (fullPath === '/archive/reviews/hotels/summary') {
        postsGetRoute = '/api/GetHotelPosts';
      } else {
        const currentUrl = window.location.href;
        const match = currentUrl.match(/\/(\d+)\//);

        if (match) {
          postsGetRoute = `/api/GetPostsForGategoryId?categoryId=${match[1]}`;
        }
      }

      categoriesRoute = '/api/GetTaxonomies';
      authorsRoute = '/api/GetAuthors';

      const [postsResponse, categoriesResponse, authorResponse] = await Promise.all([
        fetchDataAsync(postsGetRoute),
        fetchDataAsync(categoriesRoute),
        fetchDataAsync(authorsRoute)
      ]);

      setPosts(postsResponse);
      setCategories(categoriesResponse);
      setAuthors(authorResponse);

      if (categoriesResponse) {
        const filteredCategories = categoriesResponse.filter((x: { count: any; }) => x.count! >= 1).slice(0, 4);
        setDefaultCategoriesToShow(filteredCategories);
      }
    };
    fetchDataAndSetState();

    if (process.env.NODE_ENV === "production") {
      ReactGA.initialize("G-2TNMKRKWDW");
      // Track pageview
      ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: window.location.pathname });
    }
  }, []);

  useEffect(() => {
    // Recalculate filtered posts whenever posts or selectedFilter change
    filterPosts(selectedFilter);
  }, [selectedFilter, posts]);

  // Function to filter posts based on the selected filter
  const filterPosts = (filter: { name: string }) => {
    let filteredPosts = [...posts]; // Copy all posts
    switch (filter.name) {
      case "Most Recent":
        filteredPosts.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
        break;
      case "Most Appreciated":
        filteredPosts.sort((a, b) => b.like.count - a.like.count);
        break;
      case "Most Discussed":
        filteredPosts.sort((a, b) => b.commentCount - a.commentCount);
        break;
      case "Most Viewed":
        filteredPosts.sort((a, b) => b.viewdCount - a.viewdCount);
        break;
      default:
        // No filter applied
        break;
    }
    setFilteredAuthorPosts(filteredPosts);
  };

  // Calculate total number of pages
  const totalPages = Math.ceil(posts.length / itemsToShow);

  // Calculate the index range for the current page
  const startIndex = (currentPage - 1) * itemsToShow;
  const endIndex = Math.min(startIndex + itemsToShow, posts.length);

  // Get the posts for the current page
  const currentPosts = posts.slice(startIndex, endIndex);

  // Function to handle page change
  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  // Show more items on click
  const handleShowMorePosts = () => {
    setItemsToShow(filteredAuthorPosts.length); // Show all items
  };

  // Show fewer items on click
  const handleShowLessPosts = () => {
    setItemsToShow(itemsPerPage); // Show default number of items per page
  };

  // Determine if "Show more" and "Show less" buttons should be hidden
  const isShowMoreHidden = currentPage === totalPages;

  // Combine default and active categories based on the state
  const trendingCategoriesToShow = showAllCategories
    ? categories
    : defaultCategoriesToShow;

  return (
    <div className={`nc-PageArchive`}>
      {/* HEADER */}
      <div className="w-full px-2 xl:max-w-screen-2xl mx-auto">
        <div className="relative aspect-w-16 aspect-h-13 sm:aspect-h-9 lg:aspect-h-8 xl:aspect-h-5 rounded-3xl md:rounded-[40px] overflow-hidden z-0">
          <Image
            alt="archive"
            fill
            src="https://justgo.blob.core.windows.net/img/blog-summary-banner.jpg"
            className="object-cover w-full h-full rounded-3xl md:rounded-[40px]"
            sizes="(max-width: 1280px) 100vw, 1536px"
          />
          <div className="absolute inset-0 bg-black text-white bg-opacity-30 flex flex-col items-center justify-center">
            <h2 className="inline-block align-middle text-5xl font-semibold md:text-7xl ">
              Travel Blog
            </h2>
            <span className="block mt-4 text-neutral-300">{posts.length} Articles</span>
          </div>
        </div>
      </div>
      {/* ====================== END HEADER ====================== */}

      <div className="container pt-10 pb-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
        <div>
          <div className="flex flex-col sm:justify-between sm:flex-row">
            <div className="flex space-x-2.5">
              {categories ? (
                <>
                  <ModalCategories
                    categories={categories as TaxonomyType[]}
                    onCategorySelect={(selectedCategory) => {
                      const filteredPosts = posts.filter(post => {
                        const categoryId = selectedCategory.taxonomyId as number;
                        return post.categoriesId && post.categoriesId.includes(categoryId);
                      });
                      setFilteredAuthorPosts(filteredPosts);
                    }}
                    onCloseModal={() => setShowModal(false)}
                  />
                </>
              ) : (
                <div className="text-center mt-4 mb-4">Loading content ...</div>
              )}
            </div>
            <div className="block my-4 border-b w-full border-neutral-300 dark:border-neutral-500 sm:hidden"></div>
            <div className="flex justify-end">
              <ArchiveFilterListBox lists={FILTERS} onChange={setSelectedFilter} />
            </div>
          </div>

          {/* LOOP ITEMS */}
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8 mt-8 lg:mt-10">
            {currentPosts.map((post) => (
              <Card11 key={post.id} post={post} />
            ))}
          </div>

          {/* PAGINATIONS */}
          <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
            {/* Pagination controls on the left */}
            <div className="nc-Pagination inline-flex space-x-1 text-base font-medium">
              {/* Render page numbers */}
              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index + 1}
                  onClick={() => handlePageChange(index + 1)}
                  className={`inline-flex w-11 h-11 items-center justify-center rounded-full ${
                    currentPage === index + 1
                      ? 'bg-primary-6000 text-white'
                      : 'bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700'
                  } ${twFocusClass()}`}
                >
                  {index + 1}
                </button>
              ))}
            </div>

            {/* "Show me more" and "Show me less" buttons */}
            <div id="aboveTrendingTopicsContainer" className="sm:flex items-center space-x-3">
              {!isShowMoreHidden && currentPage < totalPages && (
                <ButtonPrimary className="w-full sm:w-auto" onClick={handleShowMorePosts}>
                  Show me more
                </ButtonPrimary>
              )}
              {itemsToShow !== itemsPerPage && ( // Show "Show me less" if more than default items are shown
                <ButtonPrimary className="w-full sm:w-auto" onClick={handleShowLessPosts}>
                  Show me less
                </ButtonPrimary>
              )}
            </div>
          </div>
        </div>

        {/* TRENDING TOPICS */}
        {categories && (
          <div className="relative py-16">
            <BackgroundSection />
            <SectionGridCategoryBox categories={trendingCategoriesToShow} />
            <div className="flex justify-end mt-10 md:mt-16">
              <ButtonSecondary className="w-full sm:w-auto" onClick={handleShowMoreCategories}>
                {buttonText}
              </ButtonSecondary>
            </div>
          </div>
        )}

        {/* AUTHORS */}
        {authors ? (
          <SectionSliderNewAuthors
            heading="The dream team"
            subHeading="Discover our elite writers"
            authors={authors}
          />
        ) : (
          <div className="text-center mt-4 mb-4">Loading articles...</div>
        )}

        {/* SUBSCRIBE */}
        <SectionSubscribe2 />
      </div>
    </div>
  );
};

export default PageArchive;