import ButtonPrimary from "components/Button/ButtonPrimary";
import Input from "components/Input/Input";
import Label from "components/Label/Label";
import SocialsList from "components/SocialsList/SocialsList";
import Textarea from "components/Textarea/Textarea";
import Heading2 from "components/Heading/Heading2";
import Layout from "../layout";
import emailjs from '@emailjs/browser';
import { useRef, useEffect, useState } from "react";
import { ContactFormAlert } from "components/Alert/Alert";
import ReactGA from "react-ga4";

const info = [
  {
    title: "💌 EMAIL",
    desc: "info@just-go.co.uk",
  }
];

const PageContact = () => {
 
  const emailRef = useRef<HTMLInputElement>(null);
  const nameRef = useRef<HTMLInputElement>(null);
  const messgeRef = useRef<HTMLTextAreaElement>(null);

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');

  const scrollingParagraphRef = useRef<null | HTMLDivElement>(null);

  const handleScroll = () => {
    scrollingParagraphRef.current?.scrollIntoView({block: 'start', behavior: 'smooth'});
  };

  const [values, setValues] = useState({
    from_name: '',
    reply_to: '',
    message: ''
  });

  const sendEmail = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // prevents the page from reloading when you hit “Send”
  
    try {
      setLoading(true);
      await emailjs.send('service_1wo8ptd', 'template_8sty2fk', {
        reply_to: emailRef.current?.value,
        from_name: nameRef.current?.value,
        message: messgeRef.current?.value
      }, 'kEKDLty73PiLeioyi')
        .then(function(response) {
          console.log('SUCCESS!', response.status, response.text);
        }, function(error) {
          console.log('FAILED...', error);
        });

      setValues({
        from_name: "",
        reply_to: '',
        message: ''
      });

      setStatus('success');
    } catch (error) {
      setStatus('error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      ReactGA.initialize("G-2TNMKRKWDW");
      // Track pageview
      ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: window.location.pathname });
    }
    
    if(status === 'success') {
      setTimeout(() => {
        setStatus('');
      }, 3000);
    } else {
      setTimeout(() => {
        setStatus('');
      }, 8000);
    }
  }, [status]);

  return (
    <Layout>
      <header className="text-center max-w-2xl mx-auto - mb-14 sm:mb-16 lg:mb-28 ">
        <Heading2>Don't be a stranger</Heading2>
        <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
        Have a question or just want to say hello? Drop us a message below and we will get back to you!
        </span>
        <div ref={scrollingParagraphRef}></div>
      </header>

      <div className="grid gap-8 lg:grid-cols-2">
        <div className="max-w-sm space-y-6">
          {info.map((item, index) => (
            <div key={index}>
              <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                {item.title}
              </h3>
              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                {item.desc}
              </span>
            </div>
          ))}
          <div>
            <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
              🌏 SOCIALS
            </h3>
            <SocialsList className="mt-2" />
          </div>
        </div>
        <div className="border border-neutral-100 dark:border-neutral-700 lg:hidden" ></div>
        <div>
          <ContactFormAlert result={status}></ContactFormAlert>
          <form className="grid grid-cols-1 gap-6" action="#" method="post" onSubmit={sendEmail}>
            <label className="block">
              <Label>Full name</Label>
              <Input required placeholder="Jane Doe" type="text" className="mt-1" ref={nameRef} value={values.from_name} onChange={(e) => setValues({ ...values, from_name: e.target.value })}/>
            </label>
            <label className="block">
              <Label>Email address</Label>
              <Input
                required
                type="email"
                placeholder="jane.doe@example.com"
                className="mt-1"
                ref={emailRef}
                value={values.reply_to} 
                onChange={(e) => setValues({ ...values, reply_to: e.target.value })}
              />
            </label>
            <label className="block">
              <Label>Message</Label>
              <Textarea required className="mt-1" rows={6} ref={messgeRef} value={values.message} onChange={(e) => setValues({ ...values, message: e.target.value })}/>
            </label>
            <ButtonPrimary type="submit" disabled={loading} onClick={handleScroll}>Send Message</ButtonPrimary>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default PageContact;