import React, { useEffect, useState } from "react";
import { PostAuthorType, PostDataType, TaxonomyType } from "data/types";
import ButtonPrimary from "components/Button/ButtonPrimary";
import Nav from "components/Nav/Nav";
import NavItem from "components/NavItem/NavItem";
import ArchiveFilterListBox from "components/ArchiveFilterListBox/ArchiveFilterListBox";
import Input from "components/Input/Input";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import NcImage from "components/NcImage/NcImage";
import SectionSliderNewAuthors from "components/SectionSliderNewAthors/SectionSliderNewAuthors";
import ButtonSecondary from "components/Button/ButtonSecondary";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import Card11 from "components/Card11/Card11";
import ButtonCircle from "components/Button/ButtonCircle";
import CardCategory2 from "components/CardCategory2/CardCategory2";
import Tag from "components/Tag/Tag";
import CardAuthorBox2 from "components/CardAuthorBox2/CardAuthorBox2";
import { ArrowSmallRightIcon } from "@heroicons/react/24/outline";
import ReactGA from "react-ga4";

const FILTERS = [
  { name: "Most Recent" },
  { name: "Most Appreciated" },
  { name: "Most Discussed" },
  { name: "Most Viewed" },
];

const TABS = ["Articles", "Categories", "Authors"];

const PageSearch = () => {
  const pageTitle = "Blog Search";
  const [posts, setPosts] = useState<PostDataType[]>([]);
  const [categories, setCategories] = useState<TaxonomyType[]>([]);
  const [authors, setAuthors] = useState<any[]>([]);
  const [tags, setTags] = useState<TaxonomyType[]>([]);
  const [itemsPerPage, setItemsPerPage] = useState(4);
  const [itemsToShow, setItemsToShow] = useState(4);
  const [totalPages, setTotalPages] = useState(0);
  const [tabActive, setTabActive] = useState(TABS[0]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedFilter, setSelectedFilter] = useState({ name: "Most Recent" });
  const [showAllPosts, setShowAllPosts] = useState(false);
  const [showAllCategories, setShowAllCategories] = useState(false);
  const showPostsButtonText = showAllPosts ? 'Show me less' : 'Show me more';
  const buttonText = showAllCategories ? 'Show me less' : 'Show me more';
  const [showModal, setShowModal] = useState(false);

  // Show only the top 4 categories by default when all categories are active
  const defaultCategoriesToShow = categories.filter(x => x.count! >= 1).slice(0, 4);

  const filteredPosts = posts.filter(post =>
    post.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredCats = categories.filter(cat =>
    cat.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const filteredAuthors = authors.filter(author =>
    author.firstName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const searchResults = [
    { tab: "Articles", data: filteredPosts },
    { tab: "Categories", data: filteredCats },
    { tab: "Authors", data: filteredAuthors },
  ];

  const [filteredSearchResults, setFilteredSearchResults] = useState(searchResults);

  const handleFormSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault(); // Prevent form submission
  };

  const handleClickTab = (item: string) => {
    if (item === tabActive) {
      return;
    }
    setTabActive(item);
  };

  const handleShowMoreOrLessPosts = () => {
    if (!showAllPosts) {
      setItemsToShow(posts.length); // Show all items
      setShowAllPosts(true);
    } else {
      setItemsToShow(itemsPerPage);
      setShowAllPosts(false);
    }
    
    setShowAllPosts(!showAllPosts);

    setShowModal(false);

    // Scroll to the top of the div
    const container = document.getElementById('abovePosts');
      if (container) {
        container.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      }
  };

  const handleSearchResult = () => {
    const foundTab = searchResults.find(({ data }) => data.length > 0);
    if (foundTab) {
      setTabActive(foundTab.tab);
    } else {
      // If no result is found in posts, check authors
      const foundInAuthors = authors.filter(author =>
        author.firstName.toLowerCase().includes(searchQuery.toLowerCase())
      );
      if (foundInAuthors.length > 0) {
        setTabActive('Authors');
      }
    }
  };

  // Combine default and active categories based on the state
  const trendingCategoriesToShow = showAllCategories
    ? categories
    : defaultCategoriesToShow;

  const handleShowMoreCategories = () => {
    setShowAllCategories(!showAllCategories);

    setShowModal(false);

    // Scroll to the top of the div
    const container = document.getElementById('aboveTrendingTopicsContainer');
      if (container) {
        container.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      }
  };

  // Calculate total pages whenever the data changes or the items per page are updated
  useEffect(() => {
    // Calculate the total number of pages based on the filtered search results and items to show
    const totalItems = filteredSearchResults
      .find(result => result.tab === tabActive)?.data.length || 0;
    const totalPages = Math.ceil(totalItems / itemsToShow);
    // Update the totalPages state with the calculated value
    setTotalPages(totalPages);
  }, [filteredSearchResults, itemsToShow, tabActive]);

  // Apply filtering whenever the selected filter changes
  useEffect(() => {
    filterSearchResults(selectedFilter);
  }, [selectedFilter, searchResults]);

  useEffect(() => {
    const fetchData = async (endpoint: string, expirationTime: number = 86400) => { // 86400 seconds (24 h)
      // Check if the data and timestamp are already cached in localStorage
      const cachedData = localStorage.getItem(endpoint);
      const cachedTimestamp = localStorage.getItem(`${endpoint}_timestamp`);
      
      if (cachedData && cachedTimestamp) {
        // Parse cached timestamp and calculate time difference
        const currentTime = new Date().getTime();
        const lastFetchedTime = new Date(parseInt(cachedTimestamp)).getTime();
        const elapsedTime = currentTime - lastFetchedTime;
        
        // Check if elapsed time is within the expiration time
        if (elapsedTime < expirationTime * 1000) {
          // If cached data is still valid, return it
          return JSON.parse(cachedData);
        }
      }
    
      // If data is not cached or expired, fetch it from the API
      const response = await fetch(endpoint);
      const data = await response.json();
    
      // Store the fetched data and current timestamp in localStorage for future use
      localStorage.setItem(endpoint, JSON.stringify(data));
      localStorage.setItem(`${endpoint}_timestamp`, new Date().getTime().toString());
      
      return data;
    };
  
    const fetchDataAndSetState = async () => {
      const [postsResponse, authorsResponse, categoriesResponse] = await Promise.all([
        fetchData('/api/GetPosts'),
        fetchData('/api/GetAuthors'),
        fetchData('/api/GetTaxonomies')
      ]);
  
      setPosts(postsResponse);
      setCategories(categoriesResponse);
      setAuthors(authorsResponse);
    };
  
    fetchDataAndSetState();

    if (process.env.NODE_ENV === "production") {
      ReactGA.initialize("G-2TNMKRKWDW");
      // Track pageview
      ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: window.location.pathname });
    }
  }, []);
  
  useEffect(() => {
    handleSearchResult();
  }, [searchQuery]);  

  // Update itemsToShow on window resize
  useEffect(() => {
    const handleResize = () => {
      setItemsToShow(window.innerWidth >= 640 ? 4 : 2);
    };

    // Initialize itemsToShow based on window size when component mounts
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Define type guards to check the type of each item
  function isPost(item: any): item is PostDataType {
    return item.hasOwnProperty('title'); // Check if the item has a 'title' property
  }

  function isCategory(item: any): item is TaxonomyType {
    return item.hasOwnProperty('name'); // Check if the item has a 'name' property
  }

  function isAuthor(item: any): item is PostAuthorType {
    return item.hasOwnProperty('firstName') && item.hasOwnProperty('lastName'); // Check if the item has 'firstName' and 'lastName' properties
  }

  // Function to filter search results based on the selected filter
  const filterSearchResults = (filter: { name: string }) => {
    let filteredResults: (
      | { tab: string; data: PostDataType[] }
      | { tab: string; data: TaxonomyType[] }
      | { tab: string; data: PostAuthorType[] }
    )[] = [];
  
    if (filteredSearchResults) { // Add null check
      switch (filter.name) {
        case "Most Recent":
          filteredResults = searchResults.map(result => ({
            ...result,
            data: (result.data as PostDataType[]).slice().sort((a, b) => compareDatesDesc(getDate(a), getDate(b))),
          }));
          break;
        case "Most Appreciated":
          filteredResults = searchResults.map(result => ({
            ...result,
            data: (result.data as PostDataType[]).slice().sort((a, b) => getLikes(b) - getLikes(a)),
          }));
          break;
        case "Most Discussed":
          filteredResults = searchResults.map(result => ({
            ...result,
            data: (result.data as PostDataType[]).slice().sort((a, b) => b.commentCount - a.commentCount),
          }));
          break;
        case "Most Viewed":
          filteredResults = searchResults.map(result => ({
            ...result,
            data: (result.data as PostDataType[]).slice().sort((a, b) => b.viewdCount - a.viewdCount),
          }));
          break;
        default:
          // Default to unfiltered
          filteredResults = searchResults;
          break;
      }
    }
    setFilteredSearchResults(filteredResults);
  };  

  const getDate = (data: any): string => {
    if ('date' in data) {
      return data.date;
    }
    return ''; // Default value if date property is not found
  };
  
  const getLikes = (data: any): number => {
    if ('like' in data && 'count' in data.like) {
      return data.like.count;
    }
    return 0; // Default value if like count property is not found
  };

  // Function to compare dates in descending order
  const compareDatesDesc = (dateA: string, dateB: string) => {
    const parsedDateA = new Date(dateA);
    const parsedDateB = new Date(dateB);

    if (parsedDateA > parsedDateB) return -1;
    if (parsedDateA < parsedDateB) return 1;
    return 0;
  };

  return (
    <div className={`nc-PageSearch`}>
      {/* HEADER */}
      <div className="w-screen px-2 xl:max-w-screen-2xl mx-auto">
        <div className="rounded-3xl md:rounded-[40px] relative aspect-w-16 aspect-h-9 lg:aspect-h-5 overflow-hidden z-0">
          <NcImage
            alt="search"
            fill
            containerClassName="absolute inset-0"
            src="https://images.pexels.com/photos/2138922/pexels-photo-2138922.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
            className="object-cover w-full h-full"
            sizes="(max-width: 1280px) 100vw, 1536px"
          />
        </div>
        {/* CONTENT */}
        <div  id="abovePosts" className="relative container -mt-20 lg:-mt-48">
          <div className=" bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 p-5 lg:p-16 rounded-[40px] shadow-2xl flex items-center">
            <header className="w-full max-w-3xl mx-auto text-center flex flex-col items-center">
              <h2 className="text-2xl sm:text-4xl font-semibold">{pageTitle}</h2>
                {searchQuery && (
                  <span className="block text-xs sm:text-sm mt-4 text-neutral-500 dark:text-neutral-300">
                    {searchResults.some(result => result.data.length > 0) && (
                      <span className="block text-xs sm:text-sm mt-4 text-neutral-500 dark:text-neutral-300">
                        {searchResults.some(result => result.data.length > 0) &&
                          searchResults.filter(result => result.data.length > 0).length === 1 && (
                          <>
                            We found{" "}
                            <strong className="font-medium text-neutral-800 dark:text-neutral-100">
                              {searchResults.find(result => result.data.length > 0)?.data.length}
                            </strong>{" "}
                            {searchResults.find(result => result.data.length > 0)?.tab === "Categories"
                              ? "category"
                              : searchResults.find(result => result.data.length > 0)?.tab.toLowerCase()}{" "}
                            for{" "}
                            <strong className="font-medium text-neutral-800 dark:text-neutral-100">
                              {searchQuery}
                            </strong>
                          </>
                        )}
                      </span>
                    )}
                  </span> 
                )}
              <form
                className="relative w-full mt-8 sm:mt-11 text-left"
                method="post"
              >
                <label
                  htmlFor="search-input"
                  className="text-neutral-500 dark:text-neutral-300"
                >
                  <span className="sr-only">Search all icons</span>
                  <Input
                    placeholder="Type and press enter"
                    sizeClass="pl-14 py-5 pr-5 md:pl-16"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <ButtonCircle
                    className="absolute right-2.5 top-1/2 transform -translate-y-1/2"
                    size=" w-11 h-11"
                    type="submit"
                    onClick={handleFormSubmit}
                  >
                    <ArrowSmallRightIcon className="w-6 h-6" />
                  </ButtonCircle>
                  
                  <span className="absolute left-5 top-1/2 transform -translate-y-1/2 text-2xl md:left-6">
                    <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M19.25 19.25L15.5 15.5M4.75 11C4.75 7.54822 7.54822 4.75 11 4.75C14.4518 4.75 17.25 7.54822 17.25 11C17.25 14.4518 14.4518 17.25 11 17.25C7.54822 17.25 4.75 14.4518 4.75 11Z"
                      ></path>
                    </svg>
                  </span>
                </label>
              </form>
            </header>
          </div>
        </div>  
      </div>
      {/* ====================== END HEADER ====================== */}

      <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
        <main>
          {/* TABS FILTER */}
          <div className="flex flex-col sm:items-center sm:justify-between sm:flex-row">
            <Nav
              containerClassName="w-full overflow-x-auto hiddenScrollbar"
              className="sm:space-x-2"
            >
              {TABS.map((item, index) => (
                <NavItem
                  isActive={item === tabActive}
                  key={index}
                  onClick={() => handleClickTab(item)}
                >
                  {item}
                </NavItem>
              ))}
            </Nav>
            <div className="block my-4 border-b w-full border-neutral-300 dark:border-neutral-500 sm:hidden"></div>
            {tabActive === "Articles" && (
              <div className="flex justify-end">
                <ArchiveFilterListBox lists={FILTERS} onChange={setSelectedFilter}/>
              </div>
            )}
          </div>

          {/* LOOP ITEMS */}
          {/* LOOP ITEMS POSTS */}
          {tabActive === "Articles" && (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 md:gap-8 mt-8 lg:mt-10">
              {filteredSearchResults
                .find(result => result.tab === "Articles") // Find the search result for categories
                ?.data.slice(0, itemsToShow) // Apply slice to limit the number of items
                .map(item => {
                  if (isPost(item)) {
                    // Render category card if the item is a category
                    return <Card11 key={item.id} post={item} />;
                  }
              })}
            </div>
          )}
          
          {/* LOOP ITEMS CATEGORIES */}
          {tabActive === "Categories" && (
            <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5 md:gap-8 mt-8 lg:mt-10">
              {searchResults
                .find(result => result.tab === "Categories") // Find the search result for categories
                ?.data.map(item => {
                  if (isCategory(item)) {
                    // Render category card if the item is a category
                    return <CardCategory2 key={item.id} taxonomy={item} />;
                  }
              })}
            </div>
          )}

          {/* LOOP ITEMS TAGS */}
          {tabActive === "Tags" && (
            <div className="flex flex-wrap mt-12 ">
              {tags.map((tag) => (
                <Tag className="mb-3 mr-3" key={tag.id} tag={tag} />
              ))}
            </div>
          )}
          
          {/* LOOP ITEMS POSTS */}
          {tabActive === "Authors" && (
            <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5 md:gap-8 mt-8 lg:mt-10">
              {searchResults
                .find(result => result.tab === "Authors") // Find the search result for authors
                ?.data.map(item => {
                  if (isAuthor(item)) {
                    // Render author card if the item is an author
                    return <CardAuthorBox2 key={item.id} author={item} />;
                  }
              })}
          </div>
          )}

          {tabActive === "Articles" && (
              <div className="relative bottom-2">
                <div id="aboveTrendingTopicsContainer" className="flex justify-end mt-10 md:mt-16">
                  <ButtonPrimary className="w-full sm:w-auto" onClick={handleShowMoreOrLessPosts}>{showPostsButtonText}</ButtonPrimary>
                </div>
            </div>
          )}
          
          {/* TRENDING TOPICS */}
          <div className="relative py-16 top-6">
            <BackgroundSection />
            <SectionGridCategoryBox categories={trendingCategoriesToShow}/>
              <div className="flex justify-end mt-10 md:mt-16">
                <ButtonSecondary className="w-full sm:w-auto" onClick={handleShowMoreCategories}>{buttonText}</ButtonSecondary>
              </div>
          </div>

          {/* AUTHORS */}
          <div className="relative py-16">
            <SectionSliderNewAuthors
                    heading="The dream team"
                    subHeading="Discover our elite writers"
                    authors={authors}
                  />
          </div>

          {/* SUBCRIBE */}
          <div className="relative py-16">
            <SectionSubscribe2 />
          </div>
        </main>
      </div>
    </div>
  );
};

export default PageSearch;